import React, { useEffect } from 'react';
import LogoS from '../../../assets/images/2.png';
import './index.scss';

const Logo = () => {

  useEffect(() => {
    // Get the path element inside the SVG
    const path = document.querySelector('.drawing-path');

    // Calculate the total length of the path
    const pathLength = path.getTotalLength();

    // Set the stroke-dasharray and stroke-dashoffset based on the path length
    path.style.strokeDasharray = pathLength;  // Set to the full length of the path
    path.style.strokeDashoffset = pathLength; // Initially hide the path

    // Start the drawing animation
    path.style.animation = 'drawPath 8s ease forwards'; // Trigger the animation
  }, []);

  return (
    <div className="logo-container">


      <svg
        width="559pt"
        height="897pt"
        version="1.0"
        viewBox="0 0 559 897"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient id="softGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: 'rgba(139, 0, 0, 0.7)', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: 'rgba(139, 0, 0, 0.2)', stopOpacity: 0.5 }} />
          </linearGradient>
        </defs>

        <g
          className="svg-container"
          transform="translate(0 457) scale(.1 -.1)"
          fill="none"
        >
          <path
            className="drawing-path"
            d="M5519 3028 5519-1869 4313-1869 4313 858 1332-3400 125-3400 125 3028 1362 3028 1362-1221 4343 3040 5519 3040 5519 3028"
            stroke="url(#softGradient)"
            stroke-width="12"
            opacity="0.85"
            filter="url(#shadowFilter)"
          />
        </g>
      </svg>
    </div>
  );
}

export default Logo;
