import { useEffect, useState, useRef } from 'react';
import Loader from 'react-loaders';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import emailjs from '@emailjs/browser';
import AnimatedLetters from '../AnimatedLetters';
import './index.scss';

const MapResize = () => {
  const map = useMap();
  useEffect(() => {
    if (map) {
      map.invalidateSize();
    }
  }, [map]);

  return null;
};

const Contact = () => {
  const [letterClass, setLetterClass] = useState('text-animate');
  const form = useRef();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 3000);
    return clearTimeout(timer);
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm('service_masibdn', 'template_dww56if', form.current, 'bJn0PBq3000sCP0N4')
      .then(
        () => {
          alert('Message successfully sent!');
          window.location.reload(false);
        },
        (err) => {
          alert('Failed to send the message, please try again');
        }
      );
  };

  return (
    <>
      <div className="container contact-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArr={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'u', 's']}
              idx={15}
            />
          </h1>
          <p>
            We’re excited to connect with forward-thinkers and collaborators. Whether you’re interested in transformative AI solutions, partnerships, or have a question, reach out through the form below. Let’s shape a future where technology drives progress for all.
          </p>
          <div className="contact-form">
            <form ref={form} onSubmit={sendEmail}>
              <ul>
                <li className="half">
                  <input placeholder="Name" type="text" name="name" required />
                </li>
                <li className="half">
                  <input placeholder="Email" type="email" name="email" required />
                </li>
                <li>
                  <input placeholder="Subject" type="text" name="subject" required />
                </li>
                <li>
                  <textarea placeholder="Message" name="message" required></textarea>
                </li>
                <li>
                  <input type="submit" className="flat-button" value="SEND" />
                </li>
              </ul>
            </form>
          </div>
        </div>
        <div className="info-map">
          Neurnetix,
          <br />
          Palestine,
          <br />
          Gaza <br />
          <br />
          <span>hr@neurnetix.com</span>
        </div>
        <div className="map-wrap">
          <MapContainer
            center={[31.5, 34.47]}  // Coordinates for Gaza
            zoom={10}  // Adjust zoom level as needed
            style={{ height: '100%', width: '100%' }}  // Ensure the map container fills the parent
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={[31.5, 34.47]}>
              <Popup>Neurnetix lives here, come over for a cup of coffee :)</Popup>
            </Marker>
            <MapResize />  {/* Force the map to resize after mount */}
          </MapContainer>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  );
};

export default Contact;
