import { Link } from 'react-router-dom'
import LogoS from '../../assets/images/2.png'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'
import { useState, useEffect } from 'react'
import Logo from './Logo'

const Home = () =>{
    useEffect(() =>{
        const timer = setTimeout(() => {
            setLetterClass('text-animate-hover')
       }, 4000)
       return clearTimeout(timer)
    }, [])
    const [letterClass, setLetterClass] = useState('text-animate')
    const nameArray = ['e', 'u', 'r', 'N', 'e', 't', 'i' ,'X']
    const jobArray = ['A', 'I',' ', 'P', 'r', 'o', "'", 'S']

    return (
        <div className="container home-page">
            <div className="text-zone">
                <h1>
                {/* <span className={letterClass}>H</span> 
                <span className={`${letterClass} _12`}>i</span>  */}
                {/* <br /> */}
                <span className={`${letterClass} _13`}>W</span> 
                <span className={`${letterClass} _14`}>e</span> 
                <span className={`${letterClass} _15`}>'r</span> 
                <span className={`${letterClass} _16`}>e</span> 
                <img src={LogoS} alt='AI Pro'/>
                <AnimatedLetters letterClass={letterClass}
                strArr= {nameArray}
                idx = {15}/>
                <br />
                <AnimatedLetters letterClass={letterClass}
                strArr = {jobArray}
                idx = {15}/>
                </h1>
                <h2>AI, ML Innovative solutions</h2>
                <Link to='/contact' className='flat-button'>CONTACT US</Link>
            </div>
            <Logo />
        </div>
    )
}
export default Home